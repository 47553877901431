import { Controller } from "stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
    toggle(e) {
        let target_id = $(this.element).data('toggleTargetId')
        e.stopPropagation();
        $(target_id).toggle(this.cssClass);
    }

    get cssClass() {
        return this.data.get("cssClass");
    }
}
